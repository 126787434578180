<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const progressValue = computed(() => `${(props.value * 100) / props.maxValue}%`);
</script>

<template>
	<div class="progress-wrap">
		<NuxtIcon name="20/entries" filled />
		<div class="progress">
			<div class="progress-active" />
		</div>
		<AText class="value color-custom-9" :modifiers="['bold']" as="div">
			{{ `${numberFormat(value)}/${numberFormat(maxValue)}` }}
		</AText>
	</div>
</template>

<style scoped lang="scss">
.progress-wrap {
	position: relative;
	.nuxt-icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		font-size: 37px;
		filter: drop-shadow(4px 0 4px var(--custom-rgb-13));
	}
	.progress {
		background: var(--neutral-100);
		border-radius: 20px;
		border: 3px solid var(--custom-3);
		padding: gutter(0.25) gutter(0.25) gutter(0.25) gutter(1.5);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		&-active {
			width: v-bind(progressValue);
			height: 20px;
			background-color: var(--custom-3);
			border-radius: inherit;
			transition: all 500ms ease-in-out;
		}
	}
	.value {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		white-space: nowrap;
		line-height: 1.1;
	}
	&.small {
		.nuxt-icon {
			font-size: 19px;
		}
		.progress {
			border-width: 1px;
			&-active {
				height: 10px;
			}
		}
		.value {
			right: 4px;
			font-size: 9px;
		}
	}
}
</style>
